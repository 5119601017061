<template>
  <h2 class="font-heading text-3xl text-center px-4 mb-6">Our Flavours</h2>

  <!-- Loading Skeleton -->
  <div v-if="loading">
    <Carousel arrow_color_class="text-transparent" :glide_config="glide_config" :arrows="false">
      <CarouselItem v-for="i in 5">
        <div class="pt-10 bg-gray-200">
          <svg width="100%" height="100%" viewBox="0 0 250 166" class="bg-gray-200 animate-pulse"></svg>
        </div>
      </CarouselItem>
    </Carousel>

    <div class="mt-6 h-60 mx-6 md:max-w-xl md:mx-auto bg-gray-200 animate-pulse"></div>
  </div>

  <div v-if="!loading">
      <Carousel arrow_color_class="text-sponge-navy" :glide_config="glide_config" @move="onMove">
        <CarouselItem v-for="(product, index) in all_products">
          <div class="transition-all relative border-4" :class="{'border-sponge-red': is_current(index), 'opacity-[0.8] border-sponge-navy': !is_current(index)}">
            <div class="h-10 px-2 flex items-center overflow-hidden" :class="{'bg-sponge-red text-sponge-red-contrast': is_current(index), 'bg-sponge-navy text-sponge-navy-contrast': !is_current(index)}">
              <h3 class="font-heading -mt-1 leading-none">{{ nice_title(product.name) }}</h3>
            </div>
              <img
                  :src="cloudflare_ir(product.image, {width: 250, height: 166})"
                  :srcset="`${cloudflare_ir(product.image, {width: 500, height: 332})} 2x`"
                  :alt="product.imageAlt"
                  width="250"
                  height="166"
                  class="w-full h-auto"
              >
          </div>
        </CarouselItem>
      </Carousel>

      <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="18" viewBox="0 0 36 18" class="fill-sponge-red">
          <path d="M18,0,36,18H0Z" transform="translate(36 18) rotate(180)"/>
        </svg>
      </div>

      <div class="bg-sponge-pink p-6 mx-6 mt-0.5 md:max-w-xl md:mx-auto h-60">
        <h4 class="font-heading text-xl leading-tight line-clamp-2 mb-2">{{ all_products[current_slide_index].name }}</h4>
        <p class="prose leading-normal line-clamp-4 mb-4" v-html="all_products[current_slide_index].description"></p>
        <a :href="`/cakes/brownies/${all_products[current_slide_index].concept}`"><Button color="green">Buy Me</Button></a>
      </div>
    </div>
</template>


<script setup lang="ts">
import {ref, computed, onMounted} from "vue";
import Button from "@spongecakesltd/ui/dist/vue/Shop/Button/Button.vue";

import Carousel from "@/components/Site/Carousel/Carousel.vue";
import CarouselItem from "@/components/Site/Carousel/CarouselItem.vue";
import cloudflare_ir from "@/vendor/cloudflare_ir";
import axios from "axios";

let all_products = ref([]);
let loading = ref(true);
let current_slide_index = ref(0);

const onMove = (index) => { current_slide_index.value = index; }
const is_current = (index) => index === current_slide_index.value;
const nice_title = (name) => name.replace('Brownie','').replace('Box', '').trim();

const glide_config = {
  gap: 14,
  perView: 4,
  peek: 25,
  breakpoints: {
    1100: {perView: 3},
    600: {perView: 2, peek: 50},
    400: {perView: 1, peek:50}
  }
};

onMounted(async() => {
  try {
    all_products.value = (await axios.get('/api/brownies/pieces')).data;
    if(all_products.value.length) {
      loading.value = false;
    }

  } catch(e) {}
});
</script>